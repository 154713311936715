<template>
	<div>
		<pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
	</div>
</template>
<script>
import pdf from 'vue-pdf';
export default {
	components: {
		pdf
	},
	data() {
		return {
			url: '',
			numPages: null // pdf 总页数
		};
	},
	mounted() {
		if (this.$route.query && this.$route.query.url) {
			//团号
			this.url = this.$route.query.url;
			this.getNumPages();
		} else {
			this.$util.routerPath('/404');
		}
	},
	methods: {
		// #计算pdf页码总数
		getNumPages() {
			let loadingTask = pdf.createLoadingTask(this.url);
			loadingTask.promise.then(pdf => {
				this.numPages = pdf.numPages;
			}).catch(err => {
				console.error('pdf 加载失败', err);
			});
		}
	}
};
</script>
